<template>
    <section class="view terms">
        <view-title v-bind:title="$t('message.terms.title')"></view-title>
        <div class="container py-5">
            <div class="row">
                <div class="col-md-3">
                    <ul class="nav flex-column position-sticky mb-4"
                        v-if="$i18n.locale === 'en'">
                        <li class="nav-item">
                            <a class="nav-link" href="#website-content">Website Content</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#intellectual-property">Intellectual Property</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#third-party-links">Third Party Links/Websites</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#users-responsibilities">Users Responsibilities</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#prohibited-activities">Prohibited Activities</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#dispute">Dispute</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#amendments">Amendments</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#disclaimer">Disclaimer and Limitation of Liability</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#contact">Contact Us</a>
                        </li>
                    </ul>
                    <ul class="nav flex-column position-sticky mb-4"
                        v-if="$i18n.locale === 'zh-hk'">
                        <li class="nav-item">
                            <a class="nav-link" href="#website-content">網站内容</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#intellectual-property">知識產權</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#third-party-links">連結至其他網站</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#users-responsibilities">使用者責任</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#prohibited-activities">禁止的活動</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#dispute">爭議</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#amendments">條款修改</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#disclaimer">免責聲明及法律責任限制</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#contact">聯絡我們</a>
                        </li>
                    </ul>
                    <ul class="nav flex-column position-sticky mb-4"
                        v-if="$i18n.locale === 'zh-cn'">
                        <li class="nav-item">
                            <a class="nav-link" href="#website-content">网站内容</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#intellectual-property">知识产权</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#third-party-links">连结至其他网站</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#users-responsibilities">使用者责任</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#prohibited-activities">禁止的活动</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#dispute">争议</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#amendments">条款修改</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#disclaimer">免责声明及法律责任限制</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#contact">联络我们</a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-9"
                     v-if="$i18n.locale === 'en'">
                    <p class="text-justify">
                        I-Skincare Company Limited (“marier Skincare”, “marier Salon”  or “us”) operates and provides the content on www.marierskincare.com & salon.mariersalon.com (the “Website”) to you subject to the following terms and conditions and the Privacy Policy (collectively as “the Terms of Use”).   We advise you to read the Terms of Use carefully before continuing. Accessing, browsing or using of this Website for any purpose indicates that you have read, understand and agree without limitation, to be bound and to abide by the Terms of Use.  If you do not agree to our Terms of Use, please do not use this Website.
                    </p>

                    <h5 id="website-content">Website Content</h5>
                    <p  class="text-justify">
                        The content that we provide on this Website, including, but not limited to, advertisements, product information, beauty treatment information, communication forums/platforms, user reviews, images, video, music, recommendations, promotions, free trials, Questions and Answers, etc.
                    </p>
                    <p class="text-justify">
                        marier Skincare strives our best to obtain accurate, complete, and up-to-date information from our licensors.  However, we are not liable for the accuracy and reliability of any information content, services or products provided from our licensors.
                    </p>
                    <p class="text-justify">
                        Any content provided on this Website is not meant to serve as a substitute for professional medical advice. marier Skincare suggests that you consult your doctor or any qualified healthcare professional prior to using any of the products or treatments (“Services”) offered on this Website or relying on them.
                    </p>
                    <p class="text-justify">
                        The Content, products and services offered on this Website are for consumer use only. No Content, products or service may be used or resold for benefit of any kind without prior written authorization from us.
                    </p>
                    <p class="text-justify">
                        All of the products or services purchased should be used in accordance with the instructions, guidelines and precautions provided by the related manufacturer or marier Skincare. If you have doubts as to whether any of the Services mentioned here is suitable for you, you should consult your doctor or qualified healthcare professional prior using the Service.
                    </p>

                    <h5 id="intellectual-property">Intellectual Property</h5>
                    <p class="text-justify">
                        All content of this Website including, but not limited to, trademarks, logos, product pictures, images and videos (“Materials”) appearing on this Website are the property of their respective owners and may not be copied, imitated, edited or used, in whole or in part, without prior written authorization from the related licensors and us. Using any one of such Material without prior written authorization is unlawful and we expressly reserve all rights to take legal action against such infringement.
                    </p>

                    <h5 id="third-party-links">Third Party Links/Websites</h5>
                    <p class="text-justify">
                        This Website may contain links to other internet websites that are owned, prepared and operated by third parties. marier Skincare does not monitor, approve or have any control over the content of these websites and does not represent or warrant, either express or implied, the accuracy of any information contained in any third party website. marier Skincare bears no responsibility or liability for any possible loss or damage caused in connection with your use of these websites.  You agree that your use of these thirty party links/websites will be at your own risk.
                    </p>

                    <h5 id="users-responsibilities">Users Responsibilities</h5>
                    <p class="text-justify">
                        You may choose or be required to register for an account in order to use some of the products, services and/or content on the Website.  Upon completion of registration, a username and a password will be given to you for your account and you will be responsible for maintaining the confidentiality of your account information. Registration of an account signifies that you agree to the followings:
                    </p>
                    <ul>
                        <li class="text-justify">
                            accept full responsibility and liability for all activities that occur under your account;
                        </li>
                        <li class="text-justify">
                            provide and maintain update, complete, accurate and truthful information as required;
                        </li>
                        <li class="text-justify">
                            you are at least 18 years old or with parental consent and guidance at time of use;
                        </li>
                        <li class="text-justify">
                            anything that you submit or post to the Website and/or provide to marier Skincare, including, but not limited to, comments, reviews, questions, techniques, ideas, know-how, suggestions, images and videos (the “Submissions”), other than the personally identifiable information as mentioned in the Privacy Policy, will be treated as non-proprietary and non-confidential.  And by submitting such Submissions you hereby grant us a non-exclusive, royalty-free, perpetual, worldwide, transferable, irrevocable, and fully sub-licensable right to use them in any way, even in a manner not agreeable to you, at our sole discretion and without prior notice to you;
                        </li>
                        <li class="text-justify">
                            all of your Submissions shall automatically become the sole and exclusive property of marier Skincare and shall not be returned to you;
                        </li>
                        <li class="text-justify">
                            by posting your Submissions, you represent and warrant that you have the lawful right to distribute and reproduce such Submissions;
                        </li>
                        <li class="text-justify">
                            accept any liabilities and to compensate marier Skincare, our staff and demand from related third parties for any loss, damages and expenses arising from any third party claim, action, or demand resulting from your (or anyone acting under your account) use of the Website;
                        </li>
                        <li class="text-justify">
                            you agree to indemnify marier Skincare for any loss, damages, and expenses resulting from any of your action or inaction that causes any unreasonable burden or load on the infrastructure of our Website;
                        </li>
                        <li class="text-justify">
                            you would not make any commercial use of the Content of this Website for the benefit of another business or yourself unless with prior written authorization from us;
                        </li>
                        <li class="text-justify">
                            marier Skincare does not oblige to but reserves the right to monitor and review any Submissions and the right to restrictions of posting of certain Submissions, at our sole discretion;
                        </li>
                        <li class="text-justify">
                            marier Skincare does not exercise editorial control over any Submissions and that marier Skincare shall have no responsibility or liability of any kind in connection with such Submissions;
                        </li>
                        <li class="text-justify">
                            The Submissions post by you or other users does not reflect or represent the opinion of marier Skincare;
                        </li>
                        <li class="text-justify">
                            marier Skincare reserves the right to terminate, refuse, restrict or suspend your access to your account, at our sole discretion without prior notice if you breach any of our Terms and Conditions or if it is for the best interest of any third party and marier Skincare.
                        </li>
                    </ul>

                    <h5 id="prohibited-activities">Prohibited Activities</h5>
                    <p class="text-justify">
                        Users are prohibited from transmitting any unsolicited promotions, advertisements, obscene information, computer viruses or illegal information of any kind (“Prohibited Conduct”) through www.marierskincare.com that would encourage, cause or lead to misconduct, nuisance, damage or unlawful acts.  Upon discovery or reporting of Prohibited Conduct, we reserve the right to discontinue providing the Service or suspend any customers or users from using or browsing our Website and may report such Conduct to the law enforcement authority at our sole discretion without prior notice.
                    </p>

                    <h5 id="dispute">Dispute</h5>
                    <p class="text-justify">
                        All claims, disputes, and controversies arising out of or in relation to the performance, interpretation, application, or enforcement of the Terms of Use, including but not limited to any breach thereof, shall be referred to mediation before the initiation of any adjudicative action or proceeding, including arbitration.
                    </p>

                    <h5 id="amendments">Amendments</h5>
                    <p class="text-justify">
                        marier Skincare reserves the right to change any of the terms of the Terms of Use at any time at our sole discretion.  Any changes of the Terms of Use will be effective upon its posting on the Website and the date of “Last Updated” shall be seen on the bottom of the revised page.   Your continued use of our Website following the posting of the revised or amended Terms of Use shall signify your acceptance of such changes.
                    </p>

                    <h5 id="disclaimer">Disclaimer and Limitation of Liability</h5>
                    <p class="text-justify">
                        We do our best to provide accurate, complete and up-to-date information on our Website; however, we cannot guarantee or warrant, whether express or implied, that the Content of our Website is error free or uninterrupted by technical difficulties. We accept no liability to any loss or damages of any kind arising from the use of our Website or reliance on the Content provided by this Website, whether directly or indirectly.
                    </p>
                    <p class="text-justify">
                        For the avoidance of doubt, to the extent permitted by law, in no event will marier Skincare, its suppliers, or other third parties mentioned at this Website be liable for any damages whatsoever (including, and without limitation to, those resulting from lost profits, lost data or business interruption) arising out of the use, inability to use, or the result of use of our sites, any websites linked to our sites, or the materials or information contained at any or all such sites, whether based on warranty, contract, tort or any other legal theory and whether or not advised of the possibility of such damages. You acknowledge and agree that neither marier Skincare nor its members, officers, employees or agents will be liable for any loss or damage as to servicing, repair or correction of equipment or data arising out of or resulting from your use of or the materials or information from our sites. You hereby release marier Skincare to the fullest extent from any such liability, loss, damage or claim.
                    </p>

                    <h5 id="contact">Contact Us</h5>
                    <p class="text-justify">
                        Should you have any questions regarding to the Terms of Use, please feel free to contact us during office hours from Monday to Friday at 9am – 6pm at:
                    </p>

                    <dt>
                        Email:
                    </dt>
                    <dd>
                        marketing@marierskincare.com
                    </dd>
                    <dt>
                        Phone:
                    </dt>
                    <dd>
                        (+852)3583-2928
                    </dd>
                    <dt>
                        Fax:
                    </dt>
                    <dd>
                        (+852)2619-9972
                    </dd>

                    <p class="text-justify">
                        These Terms and Conditions of Use shall be governed by the law of Hong Kong Special Administrative Region.
                    </p>
                    <p class="text-justify">
                        Last updated on January 31, 2018.
                    </p>
                    <p class="text-justify">
                        I-skincare Company Limited.  All rights reserved.
                    </p>
                    <p class="text-justify">
                        Use of this site signifies your agreement to the Terms and Conditions of Use and Privacy Policy.
                    </p>
                </div>
                <div class="col-md-9"
                     v-if="$i18n.locale === 'zh-hk'">
                    <p class="text-justify">
                        I-Skincare Company Limited (“marier Skincare”, “marier Salon”, “我們")根據以下使用條款及細則以及私隱政策（以下簡稱「條款」) 為閣下提供本網站 (www.marierskincare.com & salon.mariersalon.com) 之内容及負責管理。因此，我們建議閣下詳細閲讀本條款後才開始瀏覽或使用本網站。透過進入、瀏覽或使用本網站，表示閣下確認已閲讀，理解並同意(並無限制或保留)遵守並接受本條款之約束。如閣下不接受本條款，請停止使用本網站。
                    </p>

                    <h5 id="website-content">網站内容</h5>
                    <p class="text-justify">
                        本網站提供之内容，包括但不限於廣告、產品資料、美容療程資料、溝通論壇/平台、用家報告、圖片/影像、影片、音樂、產品及服務推介、推廣/宣傳、產品及美容療程免費試用、常見問題及解答等。
                    </p>
                    <p class="text-justify">
                        我們歇力提供由產品生產商提供之最準確，完整及最新的產品及美容療程(以下簡稱「服務」)資料予閣下。但我們不保證本網站所上載由生產商提供的所有產品及美容療程説明或資料完全準確無誤及可靠。
                    </p>
                    <p class="text-justify">
                        在本網站所提供之任何内容無意取代任何專業醫療意見。我們建議閣下在使用或依據本網站提供之任何內容、產品及服務前咨詢閣下的醫生或合資格醫療/健康顧問。
                    </p>
                    <p class="text-justify">
                        本網站提供之所有產品及服務只供顧客使用。任何人士在沒有得到我們事先允許的情況下使用或販賣本網站之任何内容或服務均屬違法。
                    </p>
                    <p class="text-justify">
                        所有購買之產品及服務必須依照相關產品生産商或我們提供之服務指引及注意事項使用。如閣下對使用之服務有任何疑問，請在使用前咨詢閣下的醫生或合資格醫療/健康顧問。
                    </p>

                    <h5 id="intellectual-property">知識產權</h5>
                    <p class="text-justify">
                        本網站所載的所有内容包括但不限於商品標簽、標誌、產品圖像、照片及音訊片段等（以下簡稱「資料」），均屬其相關持有人所擁有。任何未獲得相關擁有人及我們書面授權下對資料作出任何複製，模仿，修改或使用任何部分或全部即屬違法，我們將保留一切法律上的追究權利。
                    </p>

                    <h5 id="third-party-links">連結至其他網站</h5>
                    <p class="text-justify">
                        本網站會提供其它網站之連結，而我們沒有對這些第三者網站有任何擁有權、編制權或管理權及不會，無論明示或暗示，代表或保證第三者網站内容的準確性或真實性。我們將不會承擔或負責閣下因連結或瀏覽這些網站而構成的任何損失。閣下對連結或瀏覽這些網站必須承擔所有風險。
                    </p>

                    <h5 id="users-responsibilities">使用者責任</h5>
                    <p class="text-justify">
                        閣下在使用本網站提供之產品及服務時可能需要或選擇登記成爲我們的會員或開立戶口。開戶後，本網站會給與閣下一個用戶名稱及密碼，而閣下有責任把相關資料保密免被盜用。在閣下登記成爲會員後即代表閣下同意負責及承擔以下所有責任：
                    </p>
                    <ul>
                        <li class="text-justify">
                            接受及承擔一切在閣下戶口内作出/進行過的行爲而引致的法律責任；
                        </li>
                        <li class="text-justify">
                            提供真實，無誤，完整及更新一切所需的資料；
                        </li>
                        <li class="text-justify">
                            閣下在使用該網頁時已經年滿十八嵗或已得到監護人允許及指引；
                        </li>
                        <li class="text-justify">
                            一切閣下所提供予本網站及我們的資訊，包括但不限於評論、服務用後意見、提問、技巧、建議、知識、想法、圖/影像、短片等(以下簡稱「意見」)，而非在私隱政策所題及的可識別個人資料， 將會被當作為非專利及非機密。 閣下在提供或上載此等意見即代表閣下給與我們非專獨、免板稅、永久性的、可轉讓的、不可撤銷的和完全可再授權的權利及能以任可方式去使用，而不須通知或獲得閣下同意；
                        </li>
                        <li class="text-justify">
                            所有由閣下提供的意見將會自動成爲我們的獨有資產，並不會發還給閣下；
                        </li>
                        <li class="text-justify">
                            閣下所發帖/發佈的一切意見將被視爲閣下代表及保證已擁有該相關意見之合法使用或擁有權；
                        </li>
                        <li class="text-justify">
                            接受及承擔一切由閣下(或被他人) 因使用閣下在本網站開立之戶口而導致我們，我們的員工或相關之商業夥伴被第三者索償及採取之法律行動以及有關開支；
                        </li>
                        <li class="text-justify">
                            同意因閣下之行爲或不採取行動而造成本網站受不合理的基礎設施負荷而導致我們任何損失及費用；
                        </li>
                        <li class="text-justify">
                            閣下不會在未獲得我們事先書面授權下而使用本網站之任何内容作商業或個人使用而獲得任何利益；
                        </li>
                        <li class="text-justify">
                            我們沒有責任但保留檢測及檢閲閣下所提供之任何意見及禁止其被發帖/發佈的權利；
                        </li>
                        <li class="text-justify">
                            我們不會對意見作出任何編輯控制，因此我們對所有意見沒有任何關係，義務或承擔責任；
                        </li>
                        <li class="text-justify">
                            閣下及其他使用者所提供及發帖/發佈之意見並不反映或代表我們立場；
                        </li>
                        <li class="text-justify">
                            若閣下違反本網站的使用條款及細則或在第三者或我們作出最佳利益的前提下，我們保留作出單方面權力終止、拒絕、禁止或暫停閣下使用閣下之戶口，並不會作事前通知。
                        </li>
                    </ul>

                    <h5 id="prohibited-activities">禁止的活動</h5>
                    <p class="text-justify">
                        本網站禁止所有使用者透過本網站上載或分發任何不雅資料，電腦病毒或違法訊息，未被邀請之推廣，宣傳或廣告（以下簡稱「禁止行為」），來鼓勵，導致或構成任何不當行爲，滋擾，損失或非法行爲。如發現或有人舉報禁止行為，我們將保留一切權利終止或暫停上載者使用本網站及交由執法部門處理而並不會作出任何事前通知。
                    </p>

                    <h5 id="dispute">爭議</h5>
                    <p class="text-justify">
                        所有因本條款而產生之爭議，在採取任何司法程序，包括仲裁前，將會首先以調解方式解決。
                    </p>

                    <h5 id="amendments">條款修改</h5>
                    <p class="text-justify">
                        我們保留在有需要時修改或更生本網站提供的條款之權利。如本條款有任何修改或更生，閣下可在頁尾能看到更生及生效日期。而被修改或更生的内容亦將同時生效。閣下在修改或更生條款後繼續瀏覽或使用本網站即代表閣下同意相關修改或更生。
                    </p>

                    <p class="text-justify">
                        在法律允許的最大範圍內，在任何情況下，無論是基於保證、合約、侵權或其他任何法理,也無論是否被告知這種損害情況的可能性，marier Skincare及其供應商，或本網站中所提及的其他第三方，均無需對於因使用、無法使用，或因使用本網站與任何跟本網站連結，或任何或所有這些網站中所含之資料或資訊而造成的損失負責(包括但不限於利潤損失、數據損失或業務中斷)。閣下承認並同意marier Skincare或其職員、僱員或代理人均不需承擔任何因閣下使用本網站的資料或資訊引致需要維修、修復或糾正設備或數據，而產生的任何損失或損害。閣下在此免除marier Skincare任何該等責任、損失、損害或索償。
                    </p>

                    <h5 id="disclaimer">免責聲明及法律責任限制</h5>
                    <p class="text-justify">
                        我們會為本網站盡力提供準確、完整及最新的資料，但不會作明示或暗示的保證或承諾所提供之内容/資料是完全準確無誤或網站不會發生任何技術困難/問題。我們亦不會為閣下因使用或凴藉本網站所提供之内容而做成之任何直接或間接的損失作出任何法律責任或賠償。
                    </p>

                    <h5 id="contact">聯絡我們</h5>
                    <p class="text-justify">
                        如閣下對本條款有任何疑問，請在辦公時間星期一至五早上9點至下午6點，通過以下方式聯絡我們：
                    </p>

                    <dt>
                        電郵：
                    </dt>
                    <dd>
                        marketing@marierskincare.com
                    </dd>
                    <dt>
                        電話：
                    </dt>
                    <dd>
                        (+852)3583-2928
                    </dd>
                    <dt>
                        傳真：
                    </dt>
                    <dd>
                        (+852)2619-9972
                    </dd>

                    <p class="text-justify">
                        本條款及細則受香港特別行政區法律監管。
                    </p>
                    <p class="text-justify">
                        最後更新日期為 2018 年 1 月 31 日。
                    </p>
                    <p class="text-justify">
                        I-skincare Company Limited 保留一切權力。
                    </p>
                    <p class="text-justify">
                        使用此網站表示閣下同意接受本使用條款及細則和私隱政策。
                    </p>
                </div>
                <div class="col-md-9"
                     v-if="$i18n.locale === 'zh-cn'">
                    <p class="text-justify">
                        I-Skincare Company Limited (“marier Skincare”, “marier Salon”, “我们")根据以下使用条款及细则以及私隐政策（以下简称「条款」) 为阁下提供本网站 (www.marierskincare.com & salon.mariersalon.com) 之内容及负责管理。因此，我们建议阁下详细阅读本条款后才开始浏览或使用本网站。透过进入、浏览或使用本网站，表示阁下确认已阅读，理解并同意(并无限制或保留)遵守并接受本条款之约束。如阁下不接受本条款，请停止使用本网站。
                    </p>

                    <h5 id="website-content">网站内容</h5>
                    <p class="text-justify">
                        本网站提供之内容，包括但不限于广告、产品数据、美容疗程数据、沟通论坛/平台、用家报告、图片/影像、影片、音乐、产品及服务推介、推广/宣传、产品及美容疗程免费试用、常见问题及解答等。
                    </p>
                    <p class="text-justify">
                        我们歇力提供由产品生产商提供之最准确，完整及最新的产品及美容疗程(以下简称「服务」)数据予阁下。但我们不保证本网站所上载由生产商提供的所有产品及美容疗程说明或数据完全准确无误及可靠。
                    </p>
                    <p class="text-justify">
                        在本网站所提供之任何内容无意取代任何专业医疗意见。我们建议阁下在使用或依据本网站提供之任何内容、产品及服务前咨询阁下的医生或合资格医疗/健康顾问。
                    </p>
                    <p class="text-justify">
                        本网站提供之所有产品及服务只供顾客使用。任何人士在没有得到我们事先允许的情况下使用或贩卖本网站之任何内容或服务均属违法。
                    </p>
                    <p class="text-justify">
                        所有购买之产品及服务必须依照相关产品生产商或我们提供之服务指引及注意事项使用。如阁下对使用之服务有任何疑问，请在使用前咨询阁下的医生或合资格医疗/健康顾问。
                    </p>

                    <h5 id="intellectual-property">知识产权</h5>
                    <p class="text-justify">
                        本网站所载的所有内容包括但不限于商品标签、标志、产品图像、照片及音频片段等（以下简称「资料」），均属其相关持有人所拥有。任何未获得相关拥有人及我们书面授权下对数据作出任何复制，模仿，修改或使用任何部分或全部即属违法，我们将保留一切法律上的追究权利。
                    </p>

                    <h5 id="third-party-links">连结至其他网站</h5>
                    <p class="text-justify">
                        本网站会提供其它网站之连结，而我们没有对这些第三者网站有任何拥有权、编制权或管理权及不会，无论明示或暗示，代表或保证第三者网站内容的准确性或真实性。我们将不会承担或负责阁下因连结或浏览这些网站而构成的任何损失。阁下对连结或浏览这些网站必须承担所有风险。
                    </p>

                    <h5 id="users-responsibilities">使用者责任</h5>
                    <p class="text-justify">
                        阁下在使用本网站提供之产品及服务时可能需要或选择登记成为我们的会员或开立户口。开户后，本网站会给与阁下一个用户名称及密码，而阁下有责任把相关资料保密免被盗用。在阁下登记成为会员后即代表阁下同意负责及承担以下所有责任：
                    </p>
                    <ul>
                        <li class="text-justify">
                            接受及承担一切在阁下户口内作出/进行过的行为而引致的法律责任；
                        </li>
                        <li class="text-justify">
                            提供真实，无误，完整及更新一切所需的数据；
                        </li>
                        <li class="text-justify">
                            阁下在使用该网页时已经年满十八岁或已得到监护人允许及指引；
                        </li>
                        <li class="text-justify">
                            一切阁下所提供予本网站及我们的信息，包括但不限于评论、服务用后意见、提问、技巧、建议、知识、想法、图/影像、短片等(以下简称「意见」)，而非在私隐政策所题及的可识别个人资料， 将会被当作为非专利及非机密。 阁下在提供或上载此等意见即代表阁下给与我们非专独、免板税、永久性的、可转让的、不可撤销的和完全可再授权的权利及能以任可方式去使用，而不须通知或获得阁下同意；
                        </li>
                        <li class="text-justify">
                            所有由阁下提供的意见将会自动成为我们的独有资产，并不会发还给阁下；
                        </li>
                        <li class="text-justify">
                            阁下所发帖/发布的一切意见将被视为阁下代表及保证已拥有该相关意见之合法使用或拥有权；
                        </li>
                        <li class="text-justify">
                            接受及承担一切由阁下(或被他人) 因使用阁下在本网站开立之户口而导致我们，我们的员工或相关之商业伙伴被第三者索偿及采取之法律行动以及有关开支；
                        </li>
                        <li class="text-justify">
                            同意因阁下之行为或不采取行动而造成本网站受不合理的基础设施负荷而导致我们任何损失及费用；
                        </li>
                        <li class="text-justify">
                            阁下不会在未获得我们事先书面授权下而使用本网站之任何内容作商业或个人使用而获得任何利益；
                        </li>
                        <li class="text-justify">
                            我们没有责任但保留检测及检阅阁下所提供之任何意见及禁止其被发帖/发布的权利；
                        </li>
                        <li class="text-justify">
                            我们不会对意见作出任何编辑控制，因此我们对所有意见没有任何关系，义务或承担责任；
                        </li>
                        <li class="text-justify">
                            阁下及其他使用者所提供及发帖/发布之意见并不反映或代表我们立场；
                        </li>
                        <li class="text-justify">
                            若阁下违反本网站的使用条款及细则或在第三者或我们作出最佳利益的前提下，我们保留作出单方面权力终止、拒绝、禁止或暂停阁下使用阁下之户口，并不会作事前通知。
                        </li>
                    </ul>

                    <h5 id="prohibited-activities">禁止的活动</h5>
                    <p class="text-justify">
                        本网站禁止所有使用者透过本网站上载或分发任何不雅数据，计算机病毒或违法讯息，未被邀请之推广，宣传或广告（以下简称「禁止行为」），来鼓励，导致或构成任何不当行为，滋扰，损失或非法行为。如发现或有人举报禁止行为，我们将保留一切权利终止或暂停上载者使用本网站及交由执法部门处理而并不会作出任何事前通知。</p>

                    <h5 id="dispute">争议</h5>
                    <p class="text-justify">
                        所有因本条款而产生之争议，在采取任何司法程序，包括仲裁前，将会首先以调解方式解决。
                    </p>

                    <h5 id="amendments">条款修改</h5>
                    <p class="text-justify">
                        我们保留在有需要时修改或更生本网站提供的条款之权利。如本条款有任何修改或更生，阁下可在页尾能看到更生及生效日期。而被修改或更生的内容亦将同时生效。阁下在修改或更生条款后继续浏览或使用本网站即代表阁下同意相关修改或更生。
                    </p>
                    <p class="text-justify">
                        在法律允许的最大范围内，在任何情况下，无论是基于保证、合约、侵权或其他任何法理,也无论是否被告知这种损害情况的可能性，marier Skincare及其供货商，或本网站中所提及的其他第三方，均无需对于因使用、无法使用，或因使用本网站与任何跟本网站连结，或任何或所有这些网站中所含之数据或信息而造成的损失负责(包括但不限于利润损失、数据损失或业务中断)。阁下承认并同意marier Skincare或其职员、雇员或代理人均不需承担任何因阁下使用本网站的数据或信息引致需要维修、修复或纠正设备或数据，而产生的任何损失或损害。阁下在此免除marier Skincare任何该等责任、损失、损害或索偿。
                    </p>

                    <h5 id="disclaimer">免责声明及法律责任限制</h5>
                    <p class="text-justify">
                        我们会为本网站尽力提供准确、完整及最新的数据，但不会作明示或暗示的保证或承诺所提供之内容/数据是完全准确无误或网站不会发生任何技术困难/问题。我们亦不会为阁下因使用或凭藉本网站所提供之内容而做成之任何直接或间接的损失作出任何法律责任或赔偿。
                    </p>

                    <h5 id="contact">联络我们</h5>
                    <p class="text-justify">
                        如阁下对本条款有任何疑问，请在办公时间星期一至五早上9点至下午6点，通过以下方式联络我们：
                    </p>

                    <dt>
                        电邮：
                    </dt>
                    <dd>
                        marketing@marierskincare.com
                    </dd>
                    <dt>
                        电话：
                    </dt>
                    <dd>
                        (+852)3583-2928
                    </dd>
                    <dt>
                        传真：
                    </dt>
                    <dd>
                        (+852)2619-9972
                    </dd>

                    <p class="text-justify">
                        本使用条款及细则受香港特别行政区法律监管。
                    </p>
                    <p class="text-justify">
                        最后更新日期为 2018 年 1 月 31 日。
                    </p>
                    <p class="text-justify">
                        I-skincare Company Limited 保留一切权力。
                    </p>
                    <p class="text-justify">
                        使用此网站表示阁下同意接受本使用条款及细则和私隐政策。
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import ViewTitle from "../components/ViewTitle";
    export default {
        name: "Terms",
        components: {
            ViewTitle
        },
        metaInfo: {
            title: "條款及細則",
        },
    }
</script>

<style lang="scss" scoped>
    .terms {
        ul.nav {
            top: 65px;
        }
    }
</style>
